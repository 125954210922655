
























































































import Vue from "vue";
import create from "./components/create.vue";
import { getList, forbidUser, enableUser } from "@/api/user";
import moment from "moment";

export default Vue.extend({
  name: "UserIndex",
  filters: {
    timeFilter(value: string) {
      return moment(value).format("YYYY-MM-DD");
    }
  },
  components: {
    create
  },
  data() {
    return {
      form: {
        phone: "",
        times: []
      },
      typeList: [],
      list: [],
      page: {
        currentPage: 1,
        pageSize: 10
      },
      total: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSizeChange(val: number) {
      this.page.pageSize = val;
      this.page.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val: number) {
      this.page.currentPage = val;
      this.getList();
    },
    indexMethod(index: number) {
      return (this.page.currentPage - 1) * 10 + index + 1;
    },
    async getList() {
      let startCreateTime, endCreateTime;
      if (this.form.times && this.form.times.length > 0) {
        startCreateTime = this.form.times[0];
        endCreateTime = this.form.times[1];
      }
      const result = {
        ...this.page,
        phone: this.form.phone,
        startCreateTime,
        endCreateTime
      };
      const { data } = await getList(result);
      if (data.code === "000000") {
        this.list = data.data.records;
        this.total = data.data.total;
      } else {
        this.$message.error(data.mesg);
      }
    },
    userStatusChange(type: number, id: number) {
      const handle = type === 1 ? forbidUser : enableUser;
      const message = type === 1 ? "确认禁用吗？" : "确认启用吗？";
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const { data } = await handle(id);
          if (data.code === "000000") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(data.mesg);
          }
        })
        .catch(() => {
          // 取消执行这里
        });
    }
  }
});
